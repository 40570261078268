<template>
  <v-container fluid class="pr-0 pl-0 elevation-0">
    <offer-detail
      ref="offersView"
      v-if="Offers.state.isSelected"
      @setPreSelectItemPage="setPreSelectItemPage()"
    />
    <offer-list ref="offerList" v-else />
  </v-container>
</template>
<script>
import { inject, ref, onMounted, onUnmounted } from '@vue/composition-api';
import OfferList from './Offers/OfferList';
import OfferDetail from './Offers/OfferDetail';

export default {
  name: 'affiliate-offers',
  components: { OfferList, OfferDetail },
  data() {
    return {
      view: true,
    };
  },
  setup(props, context) {
    const Application = inject('Application');
    const Offers = inject('Offers');
    const Countries = inject('Countries');
    const offerList = ref(null);
    const offersView = ref(null);
    const setPreSelectItemPage = () => {
      setTimeout(() => {
        offerList.value.setPreSelectItemPage();
      }, 20);
    };
    onMounted(() => {
      if (context.root.$route.params._offer) {
        Offers.actions.loadAndSelectItem(context.root.$route.params._offer);
      }
    });
    onUnmounted(async () => {
      Offers.actions.resetOptions();
      Offers.actions.resetOffers();
    });
    return { Application, Offers, setPreSelectItemPage, offerList };
  },
};
</script>

<template>
  <div>
    <v-row class="mt-1">
      <span class="font-weight-bold">LOCATION</span>
    </v-row>
    <v-row class="d-flex align-center justify-center">
      <v-col style="max-width: 80px"> Country: </v-col>
      <v-col>
        <v-chip small label class="mr-1" v-for="(country, index) in offer._country" :key="index">
          <span :title="Countries[country]">{{ country }}</span>
        </v-chip>
        <v-chip small label v-if="!offer._country || offer._country.length == 0">ALL</v-chip>
      </v-col>
    </v-row>
    <v-row class="d-flex align-center justify-center">
      <v-col style="max-width: 80px"> Region: </v-col>
      <v-col>
        <v-chip
          small
          label
          class="mr-1 mb-1"
          v-for="(region, index) in offer.regionNames"
          :key="index"
        >
          {{ region }}
        </v-chip>
        <v-chip small label v-if="!offer.regionNames || offer.regionNames.length == 0">ALL</v-chip>
      </v-col>
    </v-row>
    <v-row class="d-flex align-center justify-center">
      <v-col style="max-width: 80px"> Cities: </v-col>
      <v-col>
        <v-chip small label class="mr-1 mb-1" v-for="(city, index) in offer.cityNames" :key="index">
          {{ city }}
        </v-chip>
        <v-chip small label class="mr-1" v-if="!offer.cityNames || offer.cityNames.length == 0"
          >ALL</v-chip
        >
      </v-col>
    </v-row>
    <v-row>
      <v-divider class="mb-3" />
    </v-row>
    <v-row>
      <span class="font-weight-bold">DEVICES</span>
    </v-row>
    <v-row>
      <v-col>
        <v-row class="d-flex align-center justify-center">
          <v-col style="max-width: 120px"> Device type: </v-col>
          <v-col>
            <v-chip
              small
              label
              class="mr-1 mb-1"
              v-for="(city, index) in offer.deviceType"
              :key="index"
            >
              {{ city }}
            </v-chip>
            <v-chip small label v-if="!offer.deviceType || offer.deviceType.length == 0"
              >ALL</v-chip
            >
          </v-col>
        </v-row>
      </v-col>
      <v-col>
        <v-row class="d-flex align-center justify-center">
          <v-col style="max-width: 120px"> Device OS: </v-col>
          <v-col>
            <v-chip
              small
              label
              class="mr-1 mb-1"
              v-for="(city, index) in offer.deviceOs"
              :key="index"
            >
              {{ city }}
            </v-chip>
            <v-chip small label v-if="!offer.deviceOs || offer.deviceOs.length == 0">ALL</v-chip>
          </v-col>
        </v-row>
        <v-row class="d-flex align-center justify-center">
          <v-col style="max-width: 120px"> Device OS version minumum: </v-col>
          <v-col>
            <v-chip v-if="offer.deviceOsVersionMinimum" small label>
              {{ offer.deviceOsVersionMinimum }}
            </v-chip>
            <v-chip small label v-else>ALL</v-chip>
          </v-col>
        </v-row>
        <v-row class="d-flex align-center justify-center">
          <v-col style="max-width: 120px"> Device OS version maximum: </v-col>
          <v-col>
            <v-chip v-if="offer.deviceOsVersionMaximum" small label>
              {{ offer.deviceOsVersionMaximum }}
            </v-chip>
            <v-chip small label v-else>ALL</v-chip>
          </v-col>
        </v-row>
      </v-col>
      <v-col>
        <v-row class="d-flex align-center justify-center">
          <v-col style="max-width: 120px"> Device client: </v-col>
          <v-col>
            <v-chip
              small
              label
              class="mr-1 mb-1"
              v-for="(deviceClient, index) in offer.deviceClient"
              :key="index"
            >
              {{ deviceClient }}
            </v-chip>
            <v-chip small label v-if="!offer.deviceClient || offer.deviceClient.length == 0"
              >ALL</v-chip
            >
          </v-col>
        </v-row>
        <v-row class="d-flex align-center justify-center">
          <v-col style="max-width: 120px"> Device client browser: </v-col>
          <v-col>
            <v-chip
              small
              label
              class="mr-1 mb-1"
              v-for="(deviceClientBrowser, index) in offer.deviceClientBrowser"
              :key="index"
            >
              {{ getClientBrowserName(deviceClientBrowser) }}
            </v-chip>
            <v-chip
              small
              label
              v-if="!offer.deviceClientBrowser || offer.deviceClientBrowser.length == 0"
              >ALL</v-chip
            >
          </v-col>
        </v-row>
      </v-col>
      <v-col>
        <v-row class="d-flex align-center justify-center">
          <v-col style="max-width: 120px"> Device connection type: </v-col>
          <v-col>
            <v-chip
              small
              label
              class="mr-1 mb-1"
              v-for="(deviceConnectionType, index) in offer.deviceConnectionType"
              :key="index"
            >
              {{ deviceConnectionType }}
            </v-chip>
            <v-chip
              small
              label
              v-if="!offer.deviceConnectionType || offer.deviceConnectionType.length == 0"
              >ALL</v-chip
            >
          </v-col>
        </v-row>
        <v-row class="d-flex align-center justify-center">
          <v-col style="max-width: 120px"> Device carrier: </v-col>
          <v-col>
            <v-chip
              small
              label
              class="mr-1 mb-1"
              v-for="(deviceCarrier, index) in offer.deviceCarrier"
              :key="index"
            >
              {{ deviceCarrier }}
            </v-chip>
            <v-chip small label v-if="!offer.deviceCarrier || offer.deviceCarrier.length == 0"
              >ALL</v-chip
            >
          </v-col>
        </v-row>
      </v-col>
    </v-row>
    <v-row>
      <v-divider class="mb-3" />
    </v-row>
    <v-row>
      <span class="font-weight-bold">CAPPING</span>
    </v-row>
    <v-row>
      <v-col>
        <v-row class="d-flex align-center justify-center">
          <v-col style="max-width: 120px"> Conversion capping limit: </v-col>
          <v-col>
            <v-chip small label v-if="offer.conversionCapping">
              {{ offer.conversionCappingLimit }}
            </v-chip>
            <v-chip small label v-else>OPEN CAP</v-chip>
          </v-col>
          <v-col style="max-width: 120px"> Click capping limit: </v-col>
          <v-col>
            <v-chip small label v-if="offer.clickCapping">
              {{ offer.clickCappingLimit }}
            </v-chip>
            <v-chip small label v-else>OPEN CAP</v-chip>
          </v-col>
        </v-row>
        <v-row class="d-flex align-center justify-center">
          <v-col style="max-width: 120px"> Conversion capping period: </v-col>
          <v-col>
            <v-chip small label v-if="offer.conversionCapping">
              {{ offer.conversionCappingPeriod }}
            </v-chip>
            <v-chip small label v-else>OPEN CAP</v-chip>
          </v-col>
          <v-col style="max-width: 120px"> Click capping period: </v-col>
          <v-col>
            <v-chip small label v-if="offer.clickCapping">
              {{ offer.clickCappingPeriod }}
            </v-chip>
            <v-chip small label v-else>OPEN CAP</v-chip>
          </v-col>
        </v-row>
      </v-col>
    </v-row>
  </div>
</template>
<script>
import Countries from '../../../store/constants/Countries';
import DeviceModel from '../../../store/constants/DeviceModel';
export default {
  setup() {
    const getClientBrowserName = (code) => {
      const browser = DeviceModel.deviceClientBrowser.find((browser) => {
        return browser._id === code;
      });
      return browser ? browser.name : 'unknown';
    };
    return { Countries, getClientBrowserName };
  },
  props: {
    offer: {
      type: Object,
      required: true,
    },
  },
};
</script>

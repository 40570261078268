<template>
  <v-card flat tile class="mb-3">
    <v-divider class="mx-10" />
    <v-data-table :headers="headers" :items="state.items" :loading="state.loading">
      <template v-slot:item.url="{ item }">
        <v-tooltip bottom>
          <template v-slot:activator="{ on }">
            <v-btn v-on="on" :href="item.url" target="_blank" small depressed class="mx-1">
              <v-icon>mdi-cloud-download-outline</v-icon>
            </v-btn>
          </template>
          <span>{{ $t('commons.operation.download') }}</span>
        </v-tooltip>
      </template>
    </v-data-table>
  </v-card>
</template>
<script>
import { inject, onMounted, watch, reactive } from '@vue/composition-api';
import _ from 'lodash';
import HttpClientV2 from '../../../store/HttpClientV2';

export default {
  components: {},
  data() {
    return {};
  },
  setup(props, context) {
    const state = reactive({
      loading: false,
      items: [],
      total: 0,
      item: {},
    });

    const actions = {
      load: async () => {
        try {
          state.loading = true;
          const params = {
            _offer: props._offer,
            limit: 1000,
            page: 0,
            sortBy: 'name',
            descending: -1,
          };
          const result = await HttpClientV2.callFunctionV2('GET', 'Creatives', params);
          state.items = result.resultSet;
          state.total = result.total;
        } catch (error) {
          state.items = [];
        } finally {
          state.loading = false;
        }
      },
    };
    onMounted(async () => {
      await actions.load();
    });
    const headers = [
      {
        text: 'ID',
        align: 'start',
        value: '_id',
        width: 100,
      },
      { text: 'Creative', value: 'name' },
      { text: 'Action', value: 'url', align: 'center', width: 200, filterable: false },
    ];
    return { headers, state, ...actions };
  },
  props: {
    _offer: String,
  },
};
</script>
<style lang="scss">
.v-avatar--offset {
  top: -38px;
  position: relative;
}
.full-width {
  width: 100%;
}
</style>

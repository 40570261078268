<template>
  <v-hover v-slot:default="{ hover }">
    <v-card
      class="mt-10 mx-auto"
      :elevation="0"
      open-delay="200"
      max-width="450"
      :class="{ 'on-hover': hover }"
      outlined
    >
      <v-card
        class="v-sheet--offset mx-auto"
        @click="select"
        :elevation="0"
        max-width="calc(100% - 33px)"
        outlined
        rounded
      >
        <v-img v-if="item.thumbnail" :src="item.thumbnail" aspect-ratio="1" class="grey lighten-2">
          <template v-slot:placeholder>
            <v-row class="fill-height ma-0" align="center" justify="center">
              <v-progress-circular indeterminate color="grey lighten-5"></v-progress-circular>
            </v-row>
          </template>
        </v-img>
        <v-skeleton-loader v-else class="mx-auto" aspect-ratio="1" type="image"></v-skeleton-loader>
      </v-card>
      <v-card-text class="pt-6 pb-0">
        <div class="subheading font-weight-light grey--text">ID: {{ item._id }}</div>
        <div class="subtitle-2 mb-2" style="height: 40px">{{ item.name }}</div>
        <v-chip-group v-if="item.categories" class="ma-0" :show-arrows="true">
          <v-chip v-for="(category, index) in item.categories" :key="index" label small>
            {{ category }}
          </v-chip>
        </v-chip-group>
        <v-divider class="mx-2"></v-divider>
      </v-card-text>
      <v-card-actions class="ma-0 pt-0">
        <v-chip
          class="white--text blue font-weight-bold"
          label
          small
          v-html="getPayoutField(item.payout, item.currency)"
        >
        </v-chip>
        <v-spacer />
        <v-chip
          @click="select"
          class="ma-2 text-capitalize"
          depressed
          small
          label
          color="white lighten-1"
          textColor="blue"
          style="border: 1px solid #2196f3 !important"
        >
          {{ $t('commons.titles.detailsTrackinglink') }}
          <v-icon right>mdi-chevron-right</v-icon>
        </v-chip>
      </v-card-actions>
    </v-card>
  </v-hover>
</template>
<script>
import { inject } from '@vue/composition-api';
import { getPayoutField } from '@/utils/PayoutUtils';

export default {
  name: 'offer-preview-grid',
  methods: { getPayoutField },
  components: {},
  props: {
    item: {
      type: Object,
      required: true,
      default: () => ({}),
    },
  },
  setup(props, context) {
    const select = () => context.emit('select');
    const Offers = inject('Offers');
    return { select, Offers };
  },
};
</script>
<style lang="scss">
.on-hover {
  border-width: 2px !important;
  border-color: #0088ee !important;
}
</style>

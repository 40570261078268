<template>
  <pcc-container-properties v-if="state.isSelected" :properties="false">
    <template slot="contentBar">
      <span class="title font-weight-light text--secondary">{{
        $t('model.AffiliatePixels.singular')
      }}</span>
      <v-spacer></v-spacer>
      <pcc-button-tooltip
        v-on:click="actions.unselectItem()"
        :icon="Icons.goBack"
        position="bottom"
        :tooltip="$t('commons.operation.goBack')"
        :disabled="state.loading"
      />
      <pcc-button-tooltip
        v-if="state.items.length < 6"
        v-on:click="setDefaultEvent(), actions.saveItem()"
        :icon="Icons.save"
        position="bottom"
        :tooltip="$t('commons.operation.save')"
        :loading="state.loading"
        color="primary"
        textColor="primary"
      />
    </template>
    <template slot="content">
      <v-card class="elevation-0 ma-0 pa-0" tile>
        <v-form ref="form" lazy-validation v-if="state.items.length < 6">
          <v-card-text>
            <span v-if="offer.hasEvents" class="subtitle-2 font-weight-light">{{
              $t('model.AffiliatePixels.fields.events')
            }}</span>
            <v-autocomplete
              v-if="offer.hasEvents"
              v-model="state.item.events"
              :value="state.item.events"
              :items="['All Events', 'Default', ...offer.events]"
              item-text="name"
              item-value="name"
              @input="actions.updateAttribute({ key: 'events', value: $event })"
              :required="true"
              :rules="[(v) => (v && v.length > 0) || $t('commons.rules.errorRequired')]"
              v-bind:label="$t('model.AffiliatePixels.fields.events')"
              solo-inverted
              flat
              dense
              small-chips
            />
            <span class="subtitle-2 font-weight-light">{{
              $t('model.AffiliatePixels.fields.type')
            }}</span>
            <v-select
              v-model="state.item.type"
              @input="updateAndValidate({ key: 'type', value: $event })"
              :items="selectedItemList"
              item-text="name"
              item-value="value"
              v-bind:label="$t('model.AffiliatePixels.fields.type')"
              :rules="[(v) => !!v || $t('commons.rules.errorRequired')]"
              :disabled="state.item.type === serverMethodPost"
              required
              solo-inverted
              flat
              dense
              small-chips
            >
              <template v-slot:item="data">
                <v-chip small label v-bind="data.attrs" :input-value="data.selected" class="ma-1">
                  {{ notificationType(data.item.name) }}
                </v-chip>
              </template>
              <template v-slot:selection="data">
                <v-chip small label v-bind="data.attrs" :input-value="data.selected" class="ma-1">
                  {{ notificationType(data.item.name) }}
                </v-chip>
              </template>
            </v-select>
            <span class="subtitle-2 font-weight-light">{{
              $t('model.AffiliatePixels.fields.url')
            }}</span>
            <v-text-field
              ref="url"
              v-model="state.item.url"
              @input="actions.updateItemAttribute({ key: 'url', value: $event })"
              solo-inverted
              dense
              flat
              :label="$t('model.AffiliatePixels.fields.url')"
              :required="true"
              :rules="[
                (v) => !!v || $t('commons.rules.errorRequired'),
                (v) => validateUrlOrImgTag(v) || $t('commons.rules.errorInvalidUrl'),
              ]"
            />
            <macros-glossary
              v-if="RegexTypes.regexUrl.test(state.item.url)"
              @addMacros="addMacros($event)"
            />
          </v-card-text>
        </v-form>
        <v-alert v-else type="warning" elevation="0" colored-border text>
          {{ $t('model.AffiliatePixels.limitReached') }}
        </v-alert>
      </v-card>
    </template>
    <template slot="propertiesBar"> </template>
    <template slot="properties"> </template>
  </pcc-container-properties>
  <pcc-container-properties :properties="filter" v-else>
    <template slot="contentBar">
      <span v-if="viewTitle" class="title font-weight-light text--secondary">{{
        $t('model.AffiliatePixels.plural')
      }}</span>
      <v-spacer />
      <pcc-button-tooltip
        v-on:click="
          actions.newItem({
            status: 'PENDING',
            type: 'SERVER_TO_SERVER_METHOD_GET',
            retryPolicyConfig: {},
            _affiliate: application.state.user._id,
            _offer: offer._id,
            events: 'All Events',
          })
        "
        :icon="Icons.add"
        position="bottom"
        :tooltip="$t('commons.operation.addNewItem')"
        color="primary"
        textColor="primary"
      />
      <pcc-button-show-hide
        v-if="viewFilters"
        :show="filter"
        v-on:show="filter = true"
        v-on:hide="filter = false"
        :showenIcon="Icons.switch.filter.on"
        :hiddenIcon="Icons.switch.filter.off"
        :showenTooltip="$t('commons.filter.show')"
        :hiddenTooltip="$t('commons.filter.hide')"
        :badge="Object.keys(state.filters).length"
      />
    </template>
    <template slot="content">
      <v-card class="elevation-0" tile>
        <v-card-text>
          <v-data-table
            :headers="headers"
            :items="state.items"
            :loading="state.loading"
            item-key="_id"
          >
            <template v-slot:item._id="{ item }">
              <v-tooltip top>
                <template v-slot:activator="{ on }">
                  <v-chip small label class="ma-1" v-on="on" @click="copyToClipboard(item._id)">
                    {{ item._id | truncate(20) }}
                  </v-chip>
                </template>
                <span>{{ item._id }}</span>
              </v-tooltip>
            </template>
            <template v-slot:item.status="{ item }">
              <v-chip small label class="ma-1" :color="colorByStatus(item.status)" dark>
                {{ item.status | upperCase | capitalize }}
              </v-chip>
            </template>
            <template v-slot:item.url="{ item }">
              <v-tooltip top>
                <template v-slot:activator="{ on }">
                  <v-chip small label class="ma-1" v-on="on" @click="copyToClipboard(item.url)">
                    {{ item.url | truncate(30) }}
                  </v-chip>
                </template>
                <span>{{ item.url }}</span>
              </v-tooltip>
            </template>
            <template v-slot:item.actions="{ item }">
              <pcc-button-edit-delete
                :viewEdit="viewEdit"
                v-on:edit="actions.selectItem({ value: item })"
                v-on:delete="actions.deleteItem({ value: item })"
              ></pcc-button-edit-delete>
            </template>
            <template v-slot:item.events="{ item }">
              <v-chip small label class="ma-1">
                {{ item.events }}
              </v-chip>
            </template>
            <template v-slot:item.type="{ item }">
              <v-chip small label class="ma-1">
                {{ notificationType(item.type) }}
              </v-chip>
            </template>
          </v-data-table>
        </v-card-text>
      </v-card>
    </template>
    <template slot="propertiesBar">
      <div class="subtitle-2 font-weight-light text--secondary">
        {{ $t('commons.filter.apply') }}
      </div>
      <v-spacer />
      <pcc-button-show-hide
        :show="filter"
        v-on:show="filter = true"
        v-on:hide="filter = false"
        :showenIcon="Icons.switch.filter.on"
        :hiddenIcon="Icons.closeRight"
        :showenTooltip="$t('commons.filter.show')"
        :hiddenTooltip="$t('commons.filter.hide')"
      />
    </template>
    <template slot="properties">
      <v-card elevation="0" color="transparent" outlined tile class="ma-0 pa-0">
        <v-card-text justify-space-between>
          <v-spacer class="my-3" />
          <span class="subtitle-2">{{ $t('model.AffiliatePixels.fields.status') }}</span>
          <v-select
            v-model="state.filters.status"
            @input="actions.applyFilter({ key: 'status', value: $event })"
            clearable
            :items="['ACTIVE', 'BLOCKED', 'PENDING']"
            v-bind:label="$t('model.AffiliatePixels.fields.status')"
            solo-inverted
            flat
            dense
            small-chips
            hide-details
          >
            <template v-slot:item="data">
              <v-chip
                small
                label
                v-bind="data.attrs"
                :input-value="data.selected"
                class="ma-1"
                :color="colorByStatus(data.item)"
                dark
              >
                {{ data.item | upperCase | capitalize }}
              </v-chip>
            </template>
            <template v-slot:selection="data">
              <v-chip
                small
                label
                v-bind="data.attrs"
                :input-value="data.selected"
                class="ma-1"
                :color="colorByStatus(state.filters.status)"
                dark
              >
                {{ data.item | upperCase | capitalize }}
              </v-chip>
            </template>
          </v-select>
          <v-spacer class="my-3" />
          <span class="subtitle-2">{{ $t('model.AffiliatePixels.fields.type') }}</span>
          <v-select
            v-model="state.filters.type"
            @input="actions.applyFilter({ key: 'type', value: $event })"
            clearable
            :items="typeThirdPartyTracking"
            item-text="name"
            item-value="value"
            v-bind:label="$t('model.AffiliatePixels.fields.type')"
            solo-inverted
            flat
            dense
            small-chips
            hide-details
          >
            <template v-slot:item="data">
              <v-chip small label v-bind="data.attrs" :input-value="data.selected" class="ma-1">
                {{ notificationType(data.item.name) }}
              </v-chip>
            </template>
            <template v-slot:selection="data">
              <v-chip small label v-bind="data.attrs" :input-value="data.selected" class="ma-1">
                {{ notificationType(data.item.names) }}
              </v-chip>
            </template>
          </v-select>
          <v-spacer class="my-3" />
        </v-card-text>
      </v-card>
    </template>
  </pcc-container-properties>
</template>

<script>
import { inject, onBeforeMount, onMounted, watch, reactive } from '@vue/composition-api';
import AffiliatePixels from '../../store/private/AffiliatePixels';
import PccButtonTooltip from '../Components/PccButtonTooltip';
import PccContainerProperties from '../Components/PccContainerProperties';
import PccButtonShowHide from '../Components/PccButtonShowHide';
import PccButtonEditDelete from '../Components/PccButtonEditDelete';
import MacrosGlossary from '../Components/MacrosGlossary';
import RegexTypes from '../../store/constants/RegexTypes';

export default {
  components: {
    'pcc-button-tooltip': PccButtonTooltip,
    'pcc-container-properties': PccContainerProperties,
    'pcc-button-show-hide': PccButtonShowHide,
    'pcc-button-edit-delete': PccButtonEditDelete,
    'macros-glossary': MacrosGlossary,
  },
  setup(props, context) {
    const application = inject('Application');
    const typeThirdPartyTracking = [
      {
        name: 'IMAGE_PIXEL',
        value: 'IMAGE_PIXEL',
      },
      {
        name: 'SERVER_TO_SERVER_METHOD_GET',
        value: 'SERVER_TO_SERVER_METHOD_GET',
      },
    ];
    const alternativeTypeThirdPartyTracking = [
      {
        name: 'IMAGE_PIXEL',
        value: 'IMAGE_PIXEL',
      },
      {
        name: 'SERVER_TO_SERVER_METHOD_GET',
        value: 'SERVER_TO_SERVER_METHOD_GET',
      },
      {
        name: 'SERVER_TO_SERVER_METHOD_POST',
        value: 'SERVER_TO_SERVER_METHOD_POST',
      },
    ];
    const serverMethodPost = 'SERVER_TO_SERVER_METHOD_POST';
    const self = AffiliatePixels(context, {
      _affiliate: application.state.user._id,
      _offer: props.offer._id,
    });
    onBeforeMount(async () => {
      await self.actions.loadSystemSettings();
    });
    onMounted(async () => {
      await self.actions.loadItems();
    });
    const headers = [
      { text: 'ID', value: '_id', align: 'start', width: 150 },
      { text: 'Status', value: 'status', align: 'start', width: 100, sortable: true },
      { text: 'Event', value: 'events', align: 'start', width: 220, sortable: true },
      { text: 'Type', value: 'type', align: 'start', width: 180, sortable: true },
      { text: 'Url', value: 'url', align: 'start', width: 220, sortable: true },
      { text: 'Actions', value: 'actions', align: 'center', width: 133, sortable: false },
    ];

    function notificationType(original) {
      const conversionObject = {
        IMAGE_PIXEL: 'Image Pixel',
        SCRIPT_PIXEL: 'Script Pixel',
        SERVER_TO_SERVER_METHOD_GET: 'Postback URL',
        SERVER_TO_SERVER_METHOD_POST: 'Postback POST',
        SERVER_TO_SERVER_POST: 'Postback POST',
        SERVER_TO_SERVER_GET: 'Postback URL',
      };

      return conversionObject[original];
    }
    function validateUrlOrImgTag(url) {
      if (this.state.item.type === 'IMAGE_PIXEL') {
        return RegexTypes.regexUrlOrImg.test(url);
      }
      return RegexTypes.regexUrl.test(url);
    }
    return {
      headers,
      typeThirdPartyTracking,
      alternativeTypeThirdPartyTracking,
      serverMethodPost,
      ...self,
      ...reactive({ filter: false }),
      application,
      RegexTypes,
      notificationType,
      validateUrlOrImgTag,
    };
  },
  props: {
    offer: {
      type: Object,
      required: true,
    },
    viewFilters: {
      type: Boolean,
      required: false,
      default: true,
    },
    viewTitle: {
      type: Boolean,
      required: false,
      default: true,
    },
    viewEdit: {
      type: Boolean,
      required: false,
      default: true,
    },
  },
  computed: {
    selectedItemList() {
      return this.state.item.type === this.serverMethodPost
        ? this.alternativeTypeThirdPartyTracking
        : this.typeThirdPartyTracking;
    },
  },
  methods: {
    colorByStatus(status) {
      // primary: "#42A5F5",
      // secondary: "#020202",
      // accent: "#82B1FF",
      // error: "#FF5252",
      // info: "#2196F3",
      // success: "#4CAF50",
      // warning: "#FB8C00",
      // account: "#2196F3",
      switch (status) {
        case 'ACTIVE':
          return 'green';
        case 'BLOCKED':
          return 'error';
        case 'PENDING':
          return 'primary';
        case 'DELETED':
          return 'grey';
        default:
          return 'secondary';
      }
    },
    colorByResult(result) {
      switch (result) {
        case 'SUCCESS':
          return 'green';
        case 'PENDING':
          return 'primary';
        case 'UNSUCCESSFULLY':
          return 'error';
        default:
          return 'secondary';
      }
    },
    addMacros(macros) {
      if (macros.length > 0 && RegexTypes.regexUrl.test(this.state.item.url)) {
        const urlWithMacros =
          this.state.item.url.substr(0, this.$refs.url.$refs.input.selectionStart) +
          macros +
          this.state.item.url.substr(this.$refs.url.$refs.input.selectionEnd);
        this.actions.updateItemAttribute({ key: 'url', value: urlWithMacros });
      }
    },
    setDefaultEvent() {
      if (!this.state.item.events) {
        this.state.item.events = ['All Events'];
      }
    },
    copyToClipboard(value) {
      navigator.clipboard.writeText(value);
    },
    updateAndValidate({ key, value }) {
      this.actions.updateItemAttribute({ key, value });
      this.$refs.form.validate();
    },
  },
};
</script>
